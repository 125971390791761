






import { Component, Vue } from 'vue-property-decorator'

import EventActivityForm from '@/partials/forms/Events/EventActivityForm.vue'

@Component({
  components: {
    EventActivityForm
  }
})
export default class EventActivityCreate extends Vue {

}
